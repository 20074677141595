<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-cog"></i><span class="h5"> Gestión de Itinerarios</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="6" class="my-2">
                                <b-button block variant="custom" :to="{name: 'Itinerarios'}">
                                    <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardHeader  color="primario" text-color="white">
                        <i class="fas fa-cogs"></i>
                        <span v-if="this.$route.params.id == null" class="h5"> Registrar Gestión Itinerario</span>
                        <span v-else class="h5"> Actualizar Gestión Itinerario</span>
                    </CCardHeader>
                    <CCardBody>
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(varificarAccion)">
                                <b-row>
                                    <b-col md="12">
                                        <validation-provider name="guia principal" rules="required" v-slot="{errors}">
                                            <b-form-group label="Guia Principal:" class="mb-2">
                                                <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                                <v-select :reduce="listUsuarios => listUsuarios.idGuia" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRegItinerario.idGuia , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosRegItinerario.idGuia" :options="listUsuarios" @search:blur="blurGestionItinerario">
                                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <!-- <b-col md="6">
                                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Fecha:" class="mb-2">
                                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosRegItinerario.fecha"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col> -->
                                    <b-col md="12" class="my-2">
                                        <span class="h6 text-muted">Lista de Dias</span>
                                        <CButton size="sm" class="float-right" color="dark" @click="agregarDia()">
                                            <i class="fas fa-plus fa-sm"></i> Agregar
                                        </CButton>
                                    </b-col>
                                    <b-col md="12">
                                        <template>
                                            <div class="accordion" role="tablist" v-for="(opcion, k) in datosRegItinerario.listGestionItinerario" :key="k">
                                                <b-card no-body class="mb-1">
                                                    <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                        <div block v-b-toggle="'accordion-'+k" class="bg-accordion">
                                                            <span class=" h6">{{opcion.dia = 'Día '+(k+1)}}</span>
                                                            <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                            <CButton v-if="datosRegItinerario.listGestionItinerario.length >= 2" size="sm" class="float-right" color="danger" @click="quitarDia(k)">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </CButton>
                                                        </div>
                                                    </b-card-header>
                                                    <b-collapse visible :id="'accordion-'+k" accordion="my-accordion" role="tabpanel">
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider name="descripcion" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Descripcion:" class="mb-2">
                                                                            <b-form-textarea v-model.trim="opcion.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la Descripción" rows="3" max-rows="6"></b-form-textarea>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="6">
                                                                    <validation-provider name="comidas">
                                                                        <b-form-group label="Comidas:" class="mb-2">
                                                                            <!-- <b-form-checkbox-group plain v-model="dia.comidas" :options="[{value:1,text:'Desayuno'},{value:2,text:'Almuerzo'},{value:3,text:'Cena'}]"></b-form-checkbox-group> -->
                                                                            <b-form-checkbox-group plain v-model="opcion.comida" :options="listComida" text-field="comida" value-field="id"></b-form-checkbox-group>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="6">
                                                                    <validation-provider name="distancia recorrida" :rules="{required: false}" v-slot="validationContext">
                                                                        <b-form-group label="Distancia recorrida en km:" class="mb-2">
                                                                            <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese la Distancia" v-model.trim="opcion.recorrido"></b-form-input>                                    
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12" class="mt-2">
                                                                    <span class="h6 text-muted">Actividades del día </span>
                                                                    <CButton size="sm" class="float-right" color="dark" @click="agregarActividad(k)">
                                                                        <i class="fas fa-plus fa-sm"></i> Agregar actividad
                                                                    </CButton>
                                                                </b-col>
                                                                <b-col md="12" class="mt-2">
                                                                    <b-table :items="opcion.listActividad" :fields="cabeceraActividades" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                                                        <template #table-colgroup="cabeceraActividades">
                                                                            <col v-for="field in cabeceraActividades.fields" :key="field.key" :style="{ width: field.key === 'horaInicio' ? '20%' : field.key === 'horaFin' ? '20%' : field.key === 'actividad' ? '50%' : '10%'}">
                                                                        </template>
                                                                        <template v-slot:cell(horaInicio)="rowActividad">
                                                                            <validation-provider name="hora inicio" :rules="{required: false}" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input type="time" :state="getValidationState(validationContext)" v-model.lazy="rowActividad.item.horaInicio"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </template>
                                                                        <template v-slot:cell(horaFin)="rowActividad">
                                                                            <validation-provider name="hora fin" :rules="{required: false}" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-input type="time" :state="getValidationState(validationContext)" v-model.lazy="rowActividad.item.horaFin"></b-form-input>
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </template>
                                                                        <template v-slot:cell(actividad)="rowActividad">
                                                                            <validation-provider name="actividad" :rules="{required: false}" v-slot="validationContext">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea v-model.trim="rowActividad.item.actividad" :state="getValidationState(validationContext)" placeholder="Ingrese la actividad" rows="3" max-rows="6"></b-form-textarea>
                                                                                    <!-- <b-form-textarea placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.actividad" rows="3" max-rows="6"></b-form-textarea> -->
                                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </template>
                                                                        <template #cell(acciones)="param">
                                                                            <b-button @click="quitarActividad(param, k)" v-if="opcion.listActividad.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                            </b-button>
                                                                        </template>
                                                                    </b-table>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </template>
                                    </b-col>
                                    <b-col md="12" class="text-center my-2">
                                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                            Guardar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </CCardBody>
                </CCard>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import firebase from 'firebase';
const dbUsuario = firebase.firestore().collection('users');
const dbComida = firebase.firestore().collection('tipoComida');
const dbItinerario = firebase.firestore().collection('itinerarios');

export default {
    data() {
        return {
            listUsuarios: [],
            listComida: [],
            disabled: false,

            datosRegItinerario: {
                idGuia: '',
                // fecha: moment().format('YYYY-MM-DD'),
                listGestionItinerario: [
                    {
                        dia: '',
                        descripcion: '',
                        recorrido: '',
                        comida: [],
                        listActividad: [
                            {
                                horaInicio: '',
                                horaFin: '',
                                actividad: '',
                            }
                        ],
                    }
                ]
            },

            cabeceraActividades: [
                {
                    key: 'horaInicio',
                    label: 'Hora inicio',
                    class: 'text-center'
                }, {
                    key: 'horaFin',
                    label: 'Hora fin',
                    class: 'text-center'
                }, {
                    key: 'actividad',
                    label: 'Actividad',
                    class: 'text-center'
                }, {
                    key: 'acciones',
                    label: '',
                    class: 'text-center'
                }
            ],
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurGestionItinerario() {
            this.computedForms.refs['guia principal'].validate();
        },
        listarUsuarios() {
            let me = this;

            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .where("rol","==","guia")
            .onSnapshot((querySnapshot) => {
                me.listUsuarios = [];
                querySnapshot.forEach((doc) => {
                    me.listUsuarios.push({
                        idGuia: doc.id,
                        nombre: doc.data().nombre + ' ' + doc.data().apellidoPaterno + ' ' + doc.data().apellidoMaterno,
                    });
                });
            });
        },
        listarComidas() {
            let me = this;

            dbComida
            .where("estado" , "==" , 2)
            .onSnapshot((querySnapshot) => {
                me.listComida = [];
                querySnapshot.forEach((doc) => {
                    me.listComida.push({
                        id: doc.id,
                        comida: doc.data().comida,
                    });
                });
            });
        },
        varificarAccion() {
            if (this.$route.params.id == null) {
                this.registrarItinerario();
            } else if (this.$route.params.id != null) {
                this.actualizarItinerario();
            }
        },
        agregarDia() {
            let me = this;
            me.datosRegItinerario.listGestionItinerario.push({
                dia: '',
                descripcion: '',
                recorrido: '',
                comida: [],
                listActividad: [
                    {
                        horaInicio: '',
                        horaFin: '',
                        actividad: '',
                    }
                ],
            })
        },
        quitarDia(k) {
            let me = this;
            me.datosRegItinerario.listGestionItinerario.splice(k, 1);
        },
        agregarActividad(k) {
            let me = this;

            me.datosRegItinerario.listGestionItinerario[k].listActividad.push({
                horaInicio: '',
                horaFin: '',
                actividad: ''
            });
        },
        quitarActividad(param, k) {
            let me = this;
            me.datosRegItinerario.listGestionItinerario[k].listActividad.splice(param.index, 1);
        },
        registrarItinerario() {
            let me = this;

            me.disabled = true;

            dbItinerario.add({
                idGuia: me.datosRegItinerario.idGuia,
                listGestionItinerario: me.datosRegItinerario.listGestionItinerario,
                fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                fechaEliminar: '',
                fechaModificacion: '',
                estado: 2,
                idCliente: me.$store.state.user.idCliente.trim(),
            }).then((check) =>{
                me.swat('success', 'Registrado satisfactoriamente')
                me.$router.push({
                    name: 'Itinerarios'
                });
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!');
            });
        },
        obtenerItinerario() {
            let me = this;
            me.disabled = true;

            dbItinerario.doc(me.$route.params.id).get()
            .then((doc) => {
                me.datosRegItinerario.idGuia = doc.data().idGuia;
                me.datosRegItinerario.listGestionItinerario = doc.data().listGestionItinerario;
                me.disabled = false;
            }).catch((error)=>{
                me.swat('error', 'Algo salió mal!');
                me.disabled = false;
            });
        },
        actualizarItinerario() {
            let me = this;
            me.disabled = true;

            dbItinerario.doc(me.$route.params.id).update({
                idGuia: me.datosRegItinerario.idGuia,
                listGestionItinerario: me.datosRegItinerario.listGestionItinerario,
                fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
            }).then(() => {
                me.swat('success', 'Modificado satisfactoriamente');
                me.$router.push({
                    name: 'Itinerarios'
                });
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!')
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    computed: {
        computedForms() {
            return this.$refs.observer;
        },
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            this.listarUsuarios();
            this.listarComidas();
            if(this.$route.params.id != null) {
                this.obtenerItinerario();
            }
        }
    }
}
</script>